import React from 'react'

const CallToAction = () => (
    <div class="bg-gray-50">
        <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Ready to hire?
            <br />
            <span class="text-red-600">Get in touch with us today.
            </span>
            </h2>
            <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0">
            <div class="inline-flex rounded-md shadow">
                <a href="mailto:rayburnroofing@gmail.com?subject=Website Inquiry" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                    Get Quote
                </a>
            </div>
            <div class="ml-3 inline-flex rounded-md shadow">
                <a href="tel:713-865-6014" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-600 bg-white hover:text-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                    Call Now
                </a>
            </div>
            </div>
        </div>
    </div>
)

export default CallToAction