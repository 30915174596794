import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import InspectionsBanner from "../components/inspectionsbanner"
import CallToAction from '../components/calltoaction'
import serviceAreas from '../images/service-areas.png'
import Coupon from "../components/coupon"

const ServiceAreas = () => {
  const areas = [
    { href: '/cities/the-woodlands', city: 'The Woodlands'},
    { href: '/cities/atascosita', city: 'Atascosita'},
    { href: '/cities/conroe', city: 'Conroe'},
    { href: '/cities/houston', city: 'Houston'},
    { href: '/cities/dayton', city: 'Dayton'},
    { href: '/cities/splendora', city: 'Splendora'},
    { href: '/cities/new-caney', city: 'New Caney'},
    { href: '/cities/liberty', city: 'Liberty'},
    { href: '/cities/kingwood', city: 'Kingwood'},
    { href: '/cities/humble', city: 'Humble'},
    { href: '/cities/baytown', city: 'Baytown'},
    { href: '/cities/cleveland', city: 'Cleveland'},
    { href: '/cities/crosby', city: 'Crosby'},
    { href: '/cities/huffman', city: 'Huffman'},
    { href: '/cities/porter', city: 'Porter'}
  ];

  return (
  <Layout>
    <SEO
        title="Rayburn's Roofing: Service Areas"
        description="Rayburn's Roofing provides top-quality work for homeowners and businesses alike. This is a list of a few of the areas we service."
    />
    <InspectionsBanner />
    <br />
    <div class="ml-12 mr-12">
        <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            Let us serve &nbsp;
            <span class="text-red-600">you.
            </span>
            <br class="xl:hidden" />
        </h2>
        <br />
        <p class="mt-3 text-base text-gray-700">
            We serve a large number of communities in Houston-area counties including Harris County, Montgomery County, Chambers County, and Liberty County. The map below shows the locations that 
            we service. Click on the name of your city to see what services we can provide to you.
        </p>
        <br />
        <hr />
        <br />
        <img src={serviceAreas} alt="Rayburn's Roofing is the number one roofing company for Harris County, Montgomery County, Chambers County, and Liberty County." />
    </div>
    <div class="mt-10">
      <ul class="mb-10 ml-12 mr-12 md:grid md:grid-cols-4 md:col-gap-8 md:row-gap-10">
        {areas.map((x, i) => {
          return (
            <a key={i} href={x.href} class="rounded-md hover:bg-gray-200">
            <li>
              <div class="mx-12 pt-8 pb-8">
                  <h4 class="text-lg leading-6 font-medium text-gray-900">{x.city}
                  </h4>
                  <p class="text-base leading-6 text-gray-500">
                    Texas
                  </p>
                </div>
            </li>
          </a>
          );
        })}
      </ul>
    </div>
    <br />
    <hr />
    <CallToAction />
    <Footer />
    <Coupon />
  </Layout>
)};

export default ServiceAreas
